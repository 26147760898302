p{
  font-family: "Amazon Ember";
}

.topNav {
  height: 60px;
  background: #FFFFFF;
  box-shadow: 0 1px 2px 0 rgba(11, 12, 12, 0.2);
}

.logo{
  height: 28px;
  top: 16px;
  left: 33px;
  position: absolute;
}

.treatmentSelect {
  top: 15px;
  left: 224px;
  width: 300px;
  position: absolute;
  display: inline-block;
}

.userAlias {
  color: #0F1111;
  width: fit-content;
  margin:0;
  font-size: 14px;
  line-height: 20px;
  position: absolute;
  font-weight: 600;
}

.userInfo{
  float: right;
  width: 200px;
  right: 30px;
  position: relative;
  top: 3px;
  height: 52px;
}