body{
  background: #F7F6FB;
  font-family: "Amazon Ember";
}

.layoutflow {
  height: 1000px;
}

.editForm {
  position: relative;
  border: 1px solid #7618FF;
  background: #FFFFFF;
  border-radius: 3px;
  &.true{
    background: #F9F5FF;
  }
}

.editForm .ruleName{
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: left;
}

.editForm .sectionName{
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.015em;
  text-align: left;
}

.editForm .description{
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-top: 7px;
}

.editForm .ruleDetails {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.editForm .detailIcon {
  margin-right: 15px;
}

.editForm .paramName{
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 10px;
  margin-bottom: 2px;
}

.layoutflow .controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  font-size: 12px;
}

.container {
  display: grid;
  padding: 0;
  margin: 0;
}

.tab {
  width: 50vw;
  background: #FFFFFF;
  box-shadow: 2px 4px 12px 0 rgba(11, 12, 12, 0.1);
  margin: auto;
  top: 36px;
  position: relative;
}

.treePage {
  top: 200px;
}

.topLeft {
  text-align: left;
  padding: 20px;
}

.rightItem {
  display: inline-block;
  float: right;
}

.leftItem {
  display: inline-block;
}

.header {
  height: 120px;
  width: 100%;
}

.header .title {
  width: fit-content;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  display: inline;
  position: relative;
  top: 38px;
  left: 25px;
}

.header .revert {
  float: right;
  top: 38px;
}